

.navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


* {
  margin: 0;
  padding: 0;
}
a {
  color: #fff;
  text-decoration: none;
}
.pg-footer {
  font-family: 'Roboto', sans-serif;
}


.footer {
    background-color: #004658;
    color: #fff;
}
.footer-wave-svg {
    background-color: transparent;
    display: block;
    height: 30px;
    position: relative;
    top: -1px;
    width: 100%;
}
.footer-wave-path {
    fill: #fffff2;
}

.footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 310px;
    position: relative;
}

.footer-content-column {
    box-sizing: border-box;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    color: #fff;
}

.footer-content-column ul li a {
  color: #fff;
  text-decoration: none;
}

.footer-logo-link {
    display: inline-block;
}
.footer-menu {
    margin-top: 30px;
}
.footer-menu h5 {
      padding: 0px 61px 0 0;
    }


.footer-menu-name {
    color: #fffff2;
    font-size: 15px;
    font-weight: 900;
    letter-spacing: .1em;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;
}
.footer-menu-list {
    list-style: none;
    margin-bottom: 0;
    margin-top: 10px;
    padding-left: 0;
}
.footer-menu-list li {
    margin-top: 5px;
    display: block;
}
span.iconfooter {
    padding-left: 10px;
}
.footer-call-to-action-description {
    color: #fffff2;
    margin-top: 10px;
    margin-bottom: 20px;
}
.footer-call-to-action-button:hover {
    background-color: #fffff2;
    color: #00bef0;
}
.button:last-of-type {
    margin-right: 0;
}
.footer-call-to-action-button {
    background-color: #027b9a;
    border-radius: 21px;
    color: #fffff2;
    display: inline-block;
    font-size: 11px;
    font-weight: 900;
    letter-spacing: .1em;
    line-height: 18px;
    padding: 12px 30px;
    margin: 0 10px 10px 0;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color .2s;
    cursor: pointer;
    position: relative;
}
.footer-call-to-action {
    margin-top: 30px;
}
.footer-call-to-action-title {
    color: #fffff2;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: .1em;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;
}
.footer-call-to-action-link-wrapper {
    margin-bottom: 0;
    margin-top: 10px;
    color: #fff;
    text-decoration: none;
}
.footer-call-to-action-link-wrapper a {
    color: #fff;
    text-decoration: none;
}





.footer-social-links {
    bottom: 0;
    height: 54px;
    position: absolute;
    right: 0;
    width: 236px;
}

.footer-social-amoeba-svg {
    height: 54px;
    left: 0;
    display: block;
    position: absolute;
    top: 0;
    width: 236px;
}

.footer-social-amoeba-path {
    fill: #027b9a;
}

.footer-social-link.linkedin {
    height: 26px;
    left: 3px;
    top: 11px;
    width: 26px;
}

.footer-social-link {
    display: block;
    padding: 10px;
    position: absolute;
}

.hidden-link-text {
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px,1px,1px,1px);
    -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
    clip-path: inset(0px 0px 99.9% 99.9%);
    overflow: hidden;
    height: 1px;
    width: 1px;
    padding: 0;
    border: 0;
    top: 50%;
}

.footer-social-icon-svg {
    display: block;
}

.footer-social-icon-path {
    fill: #fffff2;
    transition: fill .2s;
}

.footer-social-link.twitter {
    height: 28px;
    left: 62px;
    top: 3px;
    width: 28px;
}

.footer-social-link.youtube {
    height: 24px;
    left: 123px;
    top: 12px;
    width: 24px;
}

.footer-social-link.github {
    height: 34px;
    left: 172px;
    top: 7px;
    width: 34px;
}

.footer-copyright {
    background-color: #027b9a;
    color: #fff;
    padding: 15px 30px;
  text-align: center;
}

.footer-copyright-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
}

.footer-copyright-text {
  color: #fff;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 0;
}

.footer-copyright-link {
    color: #fff;
    text-decoration: none;
}







/* Media Query For different screens */
@media (min-width:320px) and (max-width:479px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 868px;
    position: relative;
  }
}
@media (min-width:480px) and (max-width:599px)  { /* smartphones, Android phones, landscape iPhone */
  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 1050px;
    position: relative;
  }
}
@media (min-width:600px) and (max-width: 800px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 1050px;
    position: relative;
  }
}
@media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */

}
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */

}
@media (min-width:1281px) { /* hi-res laptops and desktops */

}




@media (min-width: 760px) {
  .footer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      padding: 40px 15px 380px;
      position: relative;
  }
 

  .footer-wave-svg {
      height: 50px;
  }

  .footer-content-column {
      width: 24.99%;
  }
}
@media only screen and (max-width: 480px){
  .home-slider-content-wrapper .home-slider-title span {
    color: #f14045;
    font-size: 39px!important;
  }

  .home-slider-content-wrapper .home-slider-title {
    color: #fff;
    font-family: Montserrat, sans-serif;
    font-size: 35px!important; /* corrected from 35PX */
    font-weight: 500;
    line-height: 50px!important;
    margin-top: 20px!important;
  }

  .home-slider-content-wrapper .home-slider-desc {
    font-size: 19px!important; /* corrected from 19PX */
    line-height: 22px!important;
  }
  .toptab{
    display:block !important;
  }
  .righttab{
    display:none;
  }
  .ant-tabs-nav {
    height: 98px!important;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0);
}
}
@media only screen and (max-width: 768px){
  .toptab{
    display:block !important;
  }
  .righttab{
    display:none;
  }
  .ant-tabs-nav {
    height: 100px!important;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0);
}
}
.home iframe {
    border: 0;
    padding: 0 3px!important;
}
.video .col-10 {
    flex: 0 0 auto;
    width: 80.333333%;
    padding: 0 16px;
}
.navbar-brand>img {
    display: block;
    position: relative;
    bottom: 15px;
    height: 77px;
}
li {
    display: inline-block;
}
.navbar-set{
display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.list ul li {
    padding: 0 10px;
}
.list ul{
    display: flex;
    align-items: center;
}

/* button.btn.btn-outline-success {
    position: relative;
    right: 43px;
    bottom: 2px;
} */
input[type="search"] {
    padding: 2px 10px;
    border-color: #ff7f506b;
    width:100%;
    border-radius: 10px;
}
button {
    border: none;
    position: relative;
    right: 22px;
    background-color: transparent;
    bottom: 2px;
}
.logo img {
    width: 74px;
}
@media (min-width:200PX) and (max-width:538px)  {
    .logo img {
        width: 45px;
    }
    .form-control {
      width: 50% !important;
      /* padding:0px !important; */
    }
}
.videomenu{
    width:70%;
}
.videomenu1{
    width:30%;
}

.slick-next:before, .slick-prev:before {
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    right: 78px;
    font-size: 37px;
    position: absolute;
    color: #fd3f5a !important;
}

.videcontainer img {
    width: 180px;
    height: 80px;
}
.ant-tabs-nav {
    height: 520px;
    overflow-y: auto; /* Add vertical scrollbar */
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0); /* For Firefox */
}

/* Custom styling for scrollbar */
.ant-tabs-nav::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
}

.ant-tabs-nav::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5); /* Color of the thumb */
    border-radius: 4px; /* Round corners */
}

.ant-tabs-nav::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0); /* Color of the track */
}
.footer-logo-link img {
 align-items: center;
    width: 100%;
}
svg.footer-social-icon-svg {
    width: 26px;
}
.about img{
width:100%
}
.ant-card-body {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.about p {
    margin: 22px 0 10px;
}
.aboutvideo img {
    margin: 3px 1px;
    height: 140px;
}
.team .ant-carousel.css-dev-only-do-not-override-1qhpsh8 {
    margin-bottom: 30px;
}
/* .backgroundimage1{
    background-image: url('/public/img/slider1.jpg');
    background-repeat: no-repeat;
    background-size:cover ;
} */

/* .backgroundimage1 {
    background-image: url('/public/img/slider1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
} */
/* .home1 .ant-card.ant-card-bordered.css-dev-only-do-not-override-1qhpsh8 {
    padding: 82px;
} */

.home-slider-img-wrapper {
    height: auto;
    position: relative;
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.home-slider-content-wrapper {
    width: 100%;
}

.home-slider-content-wrapper .home-slider-title {
    font-weight: 500;
    color: #fff;
    font-size: 43PX;
    line-height: 61px;
    margin-top: 25px;
    font-family: 'Montserrat', sans-serif;
}
.home-slider-content-wrapper .home-slider-title span {
    position: relative;
    z-index: 1;
    color: #f14045;
    font-size: 44px;
}
.home-slider-content-wrapper .home-slider-desc {
    color: #fff;
    font-size: 19PX;
    line-height: 31px;
    margin: 5px 0px 33px 0;
    font-weight: var(--font-weight400);
    /* text-shadow: 0px 0 1px white, 0 1px 1px white; */
    font-family: auto;
}
.button-primary {
    color: #fff;
    background-color: #147ec2;
    background: linear-gradient(135deg,rgb(236 32 40) 0%,rgb(239 170 204) 100%);
    border-radius: 9px;
    padding: 16px 30px;
}
.bg-body-tertiary{
    margin-bottom: 0 !important;
}
/* .home1 {
    height: auto;
    padding: 80px 0;
    background: #52cffc;
    /* background: url('/public/img/slider3.jpg'); */
    /* background-size: cover;
    background-repeat: no-repeat;
} */ 
.backgroundimage1{
    height: auto;
    padding: 100px 0;
    text-align: center;
    background: url('/public/banner/banner1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.backgroundimage2{
    height: auto;
    padding: 100px 0;
    text-align: center;
    background: url('/public/banner/banner2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.backgroundimage3{
    height: auto;
    padding: 100px 0;
    background: url('/public/banner/banner3.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.backgroundimage2 .home-slider-content-wrapper .home-slider-title {
 color:#000;
 }
.backgroundimage2 .home-slider-content-wrapper .home-slider-desc {
  color:#000;
  }
/* .home1 [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
    display: none !important;
} */
.home {
    padding-top: 50px;
}
.button-primary:hover{
    color: #fff !important;
}
/* @media(max-width:991px){
.home1 {
    height: 475px;
}
}
@media (max-width: 768px){
.home1 {
    height: 340px;
    padding: 17px 0;
}
}
@media (max-width: 576px){
    .home1 {
        height: 423px;
        padding: 17px 0;
    }
    } */
    .aboutsite  {
    padding: 56px 4px;
    /* background-color: #daeff1b5; */
}
.aboutsite h3 {
    color: red;
   
}

.services-box
      {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
      }
      
      .service
      {
        margin: 8px;
      }
      
      .flip-box {
        background-color: transparent;
        width: 264px;
        height: 231px;
        border: 1px solid #f1f1f1;
        border-radius: 10px;
        perspective: 1000px;
    }
      .flip-box-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.8s;
        transform-style: preserve-3d;
        
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .flip-box:hover .flip-box-inner {
        transform: rotateY(180deg);
      }
      
      .flip-box-front, .flip-box-back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      
      .flip-box-front {
        background-color: #fff;
        color: black;
        border-radius: 10px;
        padding: 0 4px;
    }
      
      .flip-box-front img
      {
        height: 50px;
        width: 50px;
      }
      
      .flip-box-back {
        color: #fff;
        transform: rotateY(180deg);
        border-radius: 10px;
        padding: 16px;
        background: linear-gradient(135deg,rgb(236 32 40) 0%,rgb(239 170 204) 100%);
    }
.services-box .button-primary {
    color: #fff;
    background-color: #147ec2;
    background: linear-gradient(135deg,rgb(236 32 40) 0%,rgb(239 170 204) 100%);
    border-radius: 9px;
    padding: 9px 15px;
}
.myservice {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 16px 27px;
    margin-bottom: 28px;
   
}
.height-setup{
    height: 400px;
}
.gradient-text {
    background-image: linear-gradient(135deg, rgb(236 32 40) 0%, rgb(239 170 204) 100%);
    -webkit-background-clip: text;
    padding: 28px 0px;
    font-weight: 700;
    text-align: center;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    text-shadow: 1px 1px 3px #0000005e;
}
.gradient-text h4 {
    text-shadow: 1px 1px 3px #000000a1;
}
.servicepart {
    padding: 50px 0;
}
.myservice h4 {
    color: #0095ca;
    font-weight: 600;
    margin-top: 16px;
}
@media (min-width:320px) and (max-width:768px){
    .height-setup {
        height: 450px;
    }
}
  .height-set p {
    margin: 0 0 10px;
    font-size: 12px;
}
@media (min-width:1200px){
.height-setup {
    height: 400px;
}
}
.dot {
    height: 150px;
    width: 150px;
    background-color: #313131;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    padding-top: 21px;
}
.counter-column span {
    font-size: 15px;
    font-family: Montserrat,sans-serif;
    color: #fff;
    font-weight: 500;
    display: block;
    text-align: center;
    padding-top: 10px;
}
.counter-column h3 {
    font-size: 20px;
    color: #212121;
    font-weight: 400;
    text-transform: uppercase;
    padding-top: 10px;
}
.counter-column {
    text-align: center;
}

.finalheader .header {
    position: sticky;
    top: 0;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    box-shadow: 0 4px 20px hsla(207, 24%, 35%, 0.1);
    /* background-color: #151418; */
    z-index: 1;
}
  
  .finalheader nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 20px 30px; */
  }
  
  .finalheader .logo a {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
  }
  
  .finalheader .logo a span {
    color: #8739fa;
  }
  
  .finalheader .menu {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .finalheader .menu a {
    display: block;
    padding: 7px 15px;
    font-size: 17px;
    font-weight: 500;
    transition: 0.2s all ease-in-out;
    color: #000;
}
.finalheader .menu a:hover {
    color: #0095ca;
} 
  .finalheader .menu:hover a {
    opacity: 1;
  }
  
  .finalheader .menu a:hover {
    opacity: 1;
    color: #0095ca;
  }
  
  .finalheader .menu-icon {
    display: none;
  }
  
  .finalheader #menu-toggle {
    display: none;
  }
  
  .finalheader #menu-toggle:checked ~ .menu {
    transform: scale(1, 1);
  }
  
  @media only screen and (max-width: 950px) {
    .finalheader .menu {
        flex-direction: column;
        background-color: #fff;
        align-items: start;
        position: absolute;
        top: 79px;
        left: 0;
        width: 100%;
        z-index: 1;
        transform: scale(1, 0);
        transform-origin: top;
        transition: transform 0.3s ease-in-out;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
       
    }
  
    .finalheader .menu a {
      margin-left: 12px;
    }
  
    .finalheader .menu li {
      margin-bottom: 10px;
    }
  
    .finalheader .menu-icon {
      display: block;
      color: #000;
      font-size: 28px;
      cursor: pointer;
    }
    .finalheader .menu a:hover {
        opacity: 1;
        color: #0095ca;
      }
  }
  .slick-next {
    right: 0px;
}
 .slick-prev:before {
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    right: -89px;
    left: 70px;
    font-size: 37px;
    position: absolute;
    color: #fd3f5a !important;
    
}


.contact{
    background-image: url(/public/img/music2.jpg);
    background-repeat: no-repeat;
    background-size: cover;

}
.contact section {
    position: relative;
    z-index: 3;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .contact .container {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .contact .section-header {
    margin-bottom: 50px;
    text-align: center;
  }
  
  .contact .section-header h2 {
    color: #FFF;
    font-weight: bold;
    font-size: 3em;
    margin-bottom: 20px;
  }
  
  .contact .section-header p {
    color: #FFF;
  }
  
  .contact .row  {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  
  .contact .contact-info {
    width: 50%;
  }
  
  .contact .contact-info-item {
    display: flex;
    margin-bottom: 30px;
  }
  
  .contact .contact-info-icon {
    height: 70px;
    width: 70px;
    background-color: #fff;
    text-align: center;
    border-radius: 50%;
  }
  
  .contact .contact-info-icon i {
    font-size: 30px;
    line-height: 70px;
  }
  
  .contact .contact-info-content {
    margin-left: 20px;
  }
  
  .contact .contact-info-content h4 {
    color: #1da9c0;
    font-size: 1.4em;
    margin-bottom: 5px;
  }
  
  .contact .contact-info-content p {
    color: #FFF;
    font-size: 1em;
  }
  
  .contact .contact-form {
    background-color: #fff;
    padding: 40px;
    width: 45%;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  
  .contact .contact-form h2 {
    font-weight: bold;
    font-size: 2em;
    margin-bottom: 10px;
    color: #333;
  }
  
  .contact .contact-form .input-box {
    position: relative;
    width: 100%;
    margin-top: 10px;
  }
  
  .contact .contact-form .input-box input,
  .contact-form .input-box textarea{
    width: 100%;
    padding: 5px 0;
    font-size: 16px;
    margin: 10px 0;
    border: none;
    border-bottom: 2px solid #333;
    outline: none;
    resize: none;
  }
  
  .contact .contact-form .input-box span {
    position: absolute;
    left: 0;
    padding: 5px 0;
    font-size: 16px;
    margin: 10px 0;
    pointer-events: none;
    transition: 0.5s;
    color: #666;
  }
  
  .contact .contact-form .input-box input:focus ~ span,
  .contact-form .input-box textarea:focus ~ span{
    color: #e91e63;
    font-size: 12px;
    transform: translateY(-20px);
  }
  
  .contact .contact-form .input-box input[type="submit"]
  {
    width: 100%;
    /* background: #00bcd4; */
    background: linear-gradient(135deg,rgb(236 32 40) 0%,rgb(239 170 204) 100%);
    color: #FFF;
    border: 1px solid transparent;
    cursor: pointer;
    padding: 10px;
    font-size: 18px;
    border-radius: 10px;
    transition: 0.5s;
  }
  
  .contact .contact-form .input-box input[type="submit"]:hover
  {
    background: #FFF;
    color: #00bcd4;
    border: 1px solid #00bcd4; 
  }
  
  @media (max-width: 991px) {
    .contact section {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    
    .contact .row {
      flex-direction: column;
    }
    
    .contact .contact-info {
      margin-bottom: 40px;
      width: 100%;
    }
    
    .contact .contact-form {
      width: 100%;
    }
  }
  .images-al-set {
    text-align: center;
}
.home1 .slick-dots li.slick-active button:before {
    opacity: .75;
    color: #fff;
}
.home1 .slick-dots {
    bottom: 2px;
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center;
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
    z-index: 99999;
}
.about{
    background-image: url(/public/img/pagebanner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.aboutsite p {
  text-align: justify;
}

.gradient-text1 {
  text-align: left !important;
  font-size: 22px;
  padding-bottom: 0;
}
.about-manage {
  padding: 66px 0px !important;
  margin-top: 0px;
}
.finalheader {
  padding: 2px 0px;
}

.videocard {
  padding: 70px 0px;
}
.videocard .ant-card-body {
  padding: 2px;
  border-radius: 0;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
span.counter img {
  width: 50px;
}

.network button.slick-arrow.slick-next {
  display: none !important;
}
.network .slick-prev:before {
  display: none !important;
}
.dotclass {
  transform: translate(28%, 3%);
}
.network {
  padding: 39px 0px;
}
a{
  text-decoration: none;
}
.testimonial img {
   border-radius: 4em;
      }
      .testimonial {
        padding: 0 0 49px 0px;
    }
.abouttest {
  display: flex;
  justify-content: center;
}
/* .testimonial {
  padding: 20px 0px;
} */
.testimonial button.slick-arrow.slick-next {
  display: none !important;
}
.testimonial .slick-prev:before {
  display: none !important;
}
.teamimg {
  text-align: center;
}
.contact a {
  text-decoration: none !important;
  color: #fff;
}
.privacy h4 {
  font-weight: 700;
  padding-top: 20px;
}
.terms h4 {
  font-weight: 700;
  padding-top: 20px;
}
.privacy{
  padding:10px 0px
}
.terms{
  padding:10px 0px
}
.privacy p{
  text-align: justify;
}
.terms p{
  text-align: justify;
}
.pb-70 {
  padding-bottom: 70px;
}

/*================================================ 
Team Area CSS
=================================================*/
.team-card {
  margin-top: 30px;
}

  .team-card .team-card-img {
      border-radius: 25px;
  }

      .team-card .team-card-img img {
          border-radius: 25px;
          -webkit-transition: .5s ease-in-out;
          transition: .5s ease-in-out;
          width: 100%;
          border: 4px solid #0095ca;
      }

  .team-card .team-card-text {
      padding: 15px;
      text-align: center;
      border-radius: 25px;
      -webkit-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      background-color: #fff;
      -webkit-box-shadow: var(--boxShadow);
      box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
      position: relative;
      max-width: 95%;
      margin: -60px auto 0px;
  }

      .team-card .team-card-text h4 {
          font-size: var(--card-title-fontSize);
          margin-bottom: 5px;
          font-size: 17px;
      }

  .team-card:hover .team-card-img img {
      -webkit-filter: brightness(0.8);
      filter: brightness(0.8);
  }

  .team-card:hover .team-card-text {
      -webkit-transform: translateY(10px);
      transform: translateY(10px);
  }

.team-social-icons ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

  .team-social-icons ul li {
      display: inline-block;
  }

      .team-social-icons ul li a {
          width: 30px;
          height: 30px;
          margin: 0px 3px;
          font-size: 15px;
          border-radius: 50%;
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          display: inline-flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          color: #005596;
          -webkit-transition: .5s ease-in-out;
          transition: .5s ease-in-out;
          background-color: #ffffff;
          -webkit-box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
          box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
      }

          .team-social-icons ul li a:hover {
              color: var(--whiteColor);
              background-color: var(--mainColor);
          }

.our-team-logo {
  margin-bottom: 40px;
}

  .our-team-logo img {
      width: 100%;
  }

.team-card-text h4 a {
  color: #0095ca;
  font-weight: 500;
}

.section-title-wrap {
  position: relative;
}

.mb-5, .my-5 {
  margin-bottom: 3rem !important;
}

.section-title-wrap .section-title {
  background: #f0f8ff;
  border-radius: 15px;
  display: inline-block;
  position: relative;
  z-index: 2;
  color: #0095ca;
  margin-bottom: 0;
  padding: 10px 25px;
  font-size: 20px !important;
  margin-top: 55px;
}

.section-title-wrap::after {
  content: "";
  background: #f0f8ff;
  width: 100%;
  height: 2px;
  position: absolute;
  top: 72%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.team-card-text p {
  color: #000000;
  font-size: 14px;
}
.perastyle{
  font-weight: 700;
}
a.footer-call-to-action-link:hover {
  color: #fff;
  text-decoration: none;
}
.spacediv {
  padding: 10px;
}

.toptab{
  display:none;
}