ul,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

input {
  font-family: "Montserrat", "Poppins", sans-serif;
}

.navbar {
  display: none;
  /* justify-content: center;
  background: #0c0d10;
  width: 100%;
  height: 35px; */
}

.nav {
  max-width: 100%;
  width: 100%;
  background: #000;
  backdrop-filter: blur(25px);
  position: fixed;
  z-index: 99999;
  margin: 0 0 8px 0;
}

.logo-container {
  width: 100%;
  text-align: center;
}

.search-container {
  display: flex;
  align-items: center;
  padding-left: 0.7rem;
  background: #0a0b0dd4;
  border-radius: 50px;
  width: 360px;
  border: 1px solid transparent;
  overflow: hidden;
}

.search-container input {
  flex: 1;
  background-color: #23253100;
  background: none;
  border: none;
  outline: none;
  caret-color: #18e588;
  font-weight: 500;
  padding: 0.7rem 0;
  padding-left: 0.5rem;
}

.search-container input::placeholder {
  color: rgb(174, 174, 174);
}

.search-container:focus-within {
  border: 1px solid #21232b;
}

.search-container span {
  height: 25px;
  width: 0.5px;
  background: #2f3034;
}

.search-container button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #23253100;
  background: none;
  border: none;
  font-size: 1.5rem;
  outline: none;
  padding: 0.65rem;
  cursor: pointer;
  color: #a8a9b0;
}

.search-container button:hover {
  background: #8787871d;
}

.navbar ul {
  display: flex;
  list-style: none;
  padding: 0.7rem;
  align-items: center;
  justify-content: flex-end;
}

.navbar ul li {
  padding: 0 1rem;
  cursor: pointer;
}

.navbar ul li ion-icon {
  font-size: 1.8rem;
}

.navbar ul li img {
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  object-fit: cover;
}

.main {
  display: flex;
}

aside {
  padding-top: 5rem;
  margin-left: 3rem;
}

aside ul {
  list-style: none;
  backdrop-filter: blur(10px);
  width: 280px;
  background: #15161c6e;
  border-radius: 10px;
  border: 1px solid #21232b;
  overflow: hidden;
}

.active {
  color: #ffce00;
  font-weight: 600;
}

.nav-help {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

aside ul li {
  padding: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

aside ul li:hover {
  background: #21232b;
}

aside ul li ion-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

aside footer small {
  color: #4a4a4d;
}

.navigation-container {
  display: flex;
  z-index: 99999;
  height: 96vh;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  box-sizing: border-box;
  margin-right: 2rem;
}

.navigation-container div {
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  background: #15161c;
  cursor: pointer;
  border-radius: 50%;
  backdrop-filter: blur(20px);
  border: 1px solid #63646a4f;
}

.navigation-container div:hover {
  background: #63646a4f;
}

.nav-up {
  margin-top: 5rem;
}

.short-container::-webkit-scrollbar {
  display: none;
}

.short-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
  scroll-behavior: smooth;
  overflow-y: scroll;
  -webkit-scroll-snap-type: y mandatory;
  -ms-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;
  padding-top: 5rem;
  scroll-padding-top: 69px;
}

.reel {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.2rem;
  scroll-snap-align: start;
}

.reel-video {
  display: flex;
  position: relative;
}

.reel:last-child {
  padding-bottom: 10rem;
}

.reel ion-icon {
  cursor: pointer;
  z-index: 999;
}

video {
  height: 100%;
  width: 100%;
}

.video {
  position: relative;
  height: 83vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  max-width: calc((9 / 16) * 80vh);
  background: #15161c;
}

.controls {
  position: absolute;
  top: 0;
  opacity: 0;
  visibility: hidden;
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  border-radius: 10px;
  transition: all 0.2s;
  transform: translateY(-50px);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.734), transparent);
}

.video:hover>.controls {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.controls ion-icon {
  font-size: 1.3rem;
  cursor: pointer;
  color: #fff;
}

.foot {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  transition: all 0.2s;
}

.video:hover>.foot {
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(3px);
}

.foot img {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  object-fit: cover;
}

.play-pause {
  position: absolute;
  top: 50%;
  cursor: pointer;
  background: #00000075;
  display: flex;
  width: 4rem;
  height: 4rem;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.show-play-pause {
  transform: translate(-50%, -50%) scale(1) !important;
  opacity: 1 !important;
}

.play-pause ion-icon {
  font-size: 2rem;
  color: #fff;
}

.user-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
  color: #fff;
}

.title {
  color: #fff;
}

.user-info div {
  display: flex;
  align-items: center;
}

.user-info div span {
  margin-left: 0.5rem;
}

.user-info button {
  background: #18e588;
  font-weight: 600;
  border: none;
  padding: 0.5rem 2rem;
  border-radius: 7px;
  cursor: pointer;
}

.reaction {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  padding: 1rem;
}

.reaction div {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.reaction div span {
  margin-top: 5px;
}

.reaction ion-icon {
  font-size: 2rem;
}

.like {
  color: #f81348;
  animation: like 0.3s ease-in forwards;
  background: #f813482b;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.unlike {
  animation: unlike 0.17s ease-in reverse forwards;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 930px) {
  aside {
    margin-left: 1rem;
  }

  .navigation-container {
    margin-right: 1rem;
  }
}

@media (max-width: 870px) {
  aside {
    display: none;
  }
}

@media (max-width: 700px) {
  .search-container {
    display: none;
  }
}

@media (max-width: 600px) {
  .reaction {
    position: absolute;
    right: 0;
    bottom: 8rem;
    color: #fff;
  }

  .reaction .value {
    font-size: 0.8rem;
  }

  .navigation-container {
    display: none;
  }

  .short-container {
    align-items: center;
  }
}

@keyframes like {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes unlike {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.logo-containerfooter {
  top: 497px;
  width: 100%;
  background: #000000;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  position: fixed;
  z-index: 99999;
  /* margin: 4px 0; */
  text-align: center;
  padding-top: 5px;
}

.logo-containerfooter img {
  max-width: 100%;
}

.content img {
  width: 100%;
  padding: 5px;
}

.content ul {
  display: flex;
}

.ant-modal-footer {
  display: none !important;
}

.content input[type="text"] {
  WIDTH: 82%;
  PADDING: 5PX 7PX;
}

.content button {
  COLOR: #FFF;
  BACKGROUND-COLOR: #000;
  PADDING: 8PX 13PX;
  BORDER: NONE;
  BORDER-RADIUS: 8PX 8PX 8PX 8PX;
  POSITION: RELATIVE;
  RIGHT: 10PX;
}

.chat-footer button {
  COLOR: #FFF;
  BACKGROUND-COLOR: #000;
  PADDING: 8PX 13PX;
  BORDER: NONE;
  BORDER-RADIUS: 8PX 8PX 8PX 8PX;
  POSITION: RELATIVE;
  RIGHT: 10PX;
}

.chat-footer input[type="text"] {
  WIDTH: 82%;
  PADDING: 5PX 7PX;
}

.chat-message.chat-message-bot {
  BACKGROUND-COLOR: gray;
  width: fit-content;
  PADDING: 1PX 5PX;
  BORDER-RADIUS: 7PX;

}

.chat-message.chat-message-user {
  BACKGROUND-COLOR: #9bcadb99;
  width: fit-content;
  PADDING: 1PX 5PX;
  BORDER-RADIUS: 7PX;

}

/* comment box */


.card {
  border: var(--card-border);
  border-radius: 2.8px;
  width: 800px;
  margin-left: auto;
  margin-right: auto;
  font-family: arial;
  background-color: white;
}

.card-top {
  height: 50px;
  display: flex;
  border-bottom: var(--card-border);
}

.card-top a {
  cursor: pointer;
}

.card-poster-image-container {
  overflow: none;
  height: 100%;
  width: 50px;
  height: 50px;
  display: flex;
}

.card-poster-name {
  margin: auto auto auto 10px;
  font-size: 82%;
  font-weight: bold;
}

.user-pfp {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin: auto;
}

.card-image-container {
  display: flex;
}

.card-image-container img {
  width: 100%;
  border-bottom: var(--card-border);
}

.card-middle {
  display: flex;
  flex-direction: column;
  border-bottom: var(--card-border);
  padding: 10px;
}


.card-action-bar {
  display: flex;
  flex-direction: row;
}

.card-action-bar a {
  font-size: 28px;
  cursor: pointer;
}

.card-action-bar a:nth-child(1) {
  margin-right: 15px;
}

.card-action-bar a:nth-child(2) {
  margin-left: auto;
}

.card-views {
  width: 100%;
  font-weight: bold;
  font-size: 82%;
  display: flex;
}

.card-view-count {
  margin: 5px 0;
}

.card-bottom {
  display: flex;
  padding: 10px;
  height: 30px;
}



.card-bottom input {
  border: none;
  flex-grow: 1;
}

.card-bottom button {
  background-color: rgba(0, 0, 0, 0);
  opacity: 0.5;
  color: blue;
  border: none;
  padding: none;
}

.postable {
  opacity: 1 !important;
}

.post-comments {
  width: 100%;
}

.comment {
  width: 100%;
  font-size: 82%;
}

.comment .user {
  font-weight: bold;
  margin-right: 5px;
  cursor: pointer;
}

/* .comment .content {
  
} */

.comment .timestamp {
  font-size: 12px;
  margin-top: 5px;
  color: grey;
}



/* register */

/* dashbord */
li.ant-menu-item {
  line-height: 11px ! IMPORTANT;
}

.sidebarmenu aside {
  padding-top: 5rem;
  margin-left: 0rem !important;
}

#root {
  margin: 0px !important;
}

.ant-layout.ant-layout-has-sider.css-dev-only-do-not-override-1ae8k9u {
  flex-direction: row;
  height: 100vh;
}

.sidebarmenu {
  background-color: #fff;
  height: 100vh;
  overflow: hidden;
}

.topadd input[type="text"] {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
}

.topadd button {
  padding: 8px 26px;
  border-radius: 10px;
  MARGIN-TOP: 14PX;
  color: #fff;
  background-color: #1e63c5;
  border: none;
  font-weight: 700;
}

label {
  font-size: 16px;
  font-weight: 700;
}

/* CSS for table styling */
.table-container {
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead {
  background-color: #f2f2f2;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #1677ff;
  color: white;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #ddd;
}

/* CSS for buttons */
.button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.button-container button {
  margin-right: 10px;
}

.primary {
  padding: 8px 26px;
  border-radius: 10px;
  MARGIN-TOP: 14PX;
  color: #fff;
  background-color: #1e63c5;
  border: none;
  font-weight: 700;
}

.dashbord {
  padding: 20px;
  text-align: center;
  background-color: #1677ff;
  color: #fff;
  font-weight: 700;
  margin: 0px;
  border-radius: 10px;
}


/* signuppage */

* {

  margin: 0;

  padding: 0;

  box-sizing: border-box;

  font-family: "Poppins", sans-serif;

}

.signupform {

  display: flex;

  justify-content: center;

  align-items: center;

  min-height: 100vh;

  background: url(https://images.unsplash.com/photo-1501975558162-0be7b8ca95ea?crop=entropy&cs=srgb&fm=jpg&ixid=M3wzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE3MDk2NTI3NDJ8&ixlib=rb-4.0.3&q=85) no-repeat;

  background-size: cover;

  background-position: center;

  padding: 50px 30px;

  background-attachment: fixed;


}

.signupform .wrapper {
  width: 90%;

  background: transparent;

  border: 1px solid white;

  backdrop-filter: blur(4px);

  color: #fff;

  border-radius: 12px;

  padding: 30px 40px;

  display: none;

}

.signupform .wrapper h1 {

  font-size: 36px;

  text-align: center;

}

.signupform .wrapper .input-box {

  position: relative;

  width: 100%;

  height: 50px;



  margin: 30px 0;

}

.signupform .input-box input {

  width: 100%;

  height: 100%;

  background: transparent;

  border: none;

  outline: none;

  border: 1px solid white;

  border-radius: 40px;

  font-size: 16px;

  color: #fff;

  padding: 20px 45px 20px 20px;

}

.signupform .input-box input::placeholder {

  color: #fff;

}

.signupform .input-box i {

  position: absolute;

  right: 20px;

  top: 30%;

  transform: translate(-50%);

  font-size: 20px;

}

.signupform .wrapper .checkbox1 {

  display: flex;

  justify-content: space-between;

  font-size: 14.5px;

  margin: -15px 0 15px;

}

.signupform .checkbox1 label input {

  accent-color: #fff;

  margin-right: 3px;

}

.signupform .checkbox1 a {

  color: #fff;

  text-decoration: none;

}

.signupform .checkbox1 a:hover {

  text-decoration: underline;

}

.signupform .wrapper .btn {

  width: 100%;

  height: 45px;

  background: #fff;

  border: none;

  outline: none;

  border-radius: 40px;



  border: 1px solid white;

  box-shadow: 0 0 10px rgba(0, 0, 0, .1);

  cursor: pointer;

  font-size: 16px;

  color: #333;

  font-weight: 600;

}

.signupform .wrapper .link {

  font-size: 14.5px;

  text-align: center;

  margin: 20px 0 15px;

}

.signupform .link p a {

  color: #fff;

  text-decoration: none;

  font-weight: 600;

}

.signupform .link p a:hover {

  text-decoration: underline;

}

.signupform #loginForm:checked~#loginFormContent,

#registerForm:checked~#registerFormContent,

#forgotForm:checked~#forgotFormContent {

  display: block;

}

.signupform input[type="radio"] {

  display: none;

}

.reel-video1 {
  display: block;
  position: relative;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark.css-dev-only-do-not-override-1qhpsh8 {
  overflow-y: scroll;
  height: 100%;
  padding: 10px 0px;
}

span.ant-menu-title-content {
  overflow: visible;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark.css-dev-only-do-not-override-1qhpsh8::-webkit-scrollbar,
main.ant-layout-content.css-dev-only-do-not-override-1qhpsh8::-webkit-scrollbar {
  display: none;
}

button.ant-btn.css-dev-only-do-not-override-1qhpsh8.ant-btn-primary,
button.primary {
  left: 0;
}

.table-container table td,
.table-container table th {
  padding: 10px 15px;
}